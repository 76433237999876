import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { EventEmitter } from '@mini-code/base-func';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "eventemitter--订阅发布"
    }}>{`EventEmitter / 订阅发布`}</h1>
    <p>{`basic-helper 提供订阅发布机制，用于取代需要依赖 `}<inlineCode parentName="p">{`document`}</inlineCode>{` 的 `}<inlineCode parentName="p">{`CustomEvent`}</inlineCode></p>
    <Playground __position={0} __code={'() => {\n  const eventName = \'eventName\'\n  const handle = res => {\n    console.log(res)\n  }\n  return (\n    <div>\n      <span\n        onClick={e => {\n          EventEmitter.emit(eventName, 123)\n        }}\n      >\n        触发广播\n      </span>\n      <hr />\n      <span\n        onClick={e => {\n          EventEmitter.rm(eventName, handle)\n        }}\n      >\n        移除广播\n      </span>\n      <hr />\n      <span\n        onClick={e => {\n          EventEmitter.on(eventName, handle)\n        }}\n      >\n        监听\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      EventEmitter
    }} mdxType="Playground">
  {() => {
        const eventName = 'eventName';

        const handle = res => {
          console.log(res);
        };

        return <div>
          <span onClick={e => {
            EventEmitter.emit(eventName, 123);
          }}>触发广播</span>
          <hr />
          <span onClick={e => {
            EventEmitter.rm(eventName, handle);
          }}>移除广播</span>
          <hr />
          <span onClick={e => {
            EventEmitter.on(eventName, handle);
          }}>监听</span>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "使用"
    }}>{`使用`}</h2>
    <p>{`可以在任意位置使用`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { EventEmitter } from 'basic-helper';

const handleEvent = (emittedData) => {
  console.log(emittedData)
}

EventEmitter.on('EVENT_NAME', handleEvent);

EventEmitter.emit('EVENT_NAME', {
  state: 'success'
});
`}</code></pre>
    <h2 {...{
      "id": "构造新实例"
    }}>{`构造新实例`}</h2>
    <p>{`可以构造一个全新的订阅发布对象`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { EventEmitterClass } from 'basic-helper';

const EventEmitter = new EventEmitterClass();

const handleEvent = (emittedData) => {
  console.log(emittedData)
}

EventEmitter.on('EVENT_NAME', handleEvent);

EventEmitter.emit('EVENT_NAME', {
  state: 'success'
});
`}</code></pre>
    <h2 {...{
      "id": "继承扩展"
    }}>{`继承扩展`}</h2>
    <p>{`通过继承 EventEmitterClass，获取订阅发布能力。以下为异步请求类的封装`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { EventEmitterClass } from 'basic-helper';

class Request extends EventEmitterClass {
  constructor() {
    super();
  }
  post() {
    fetch('url')
      .then(res => res.json())
      .then(res => {
        this.emit('onPostRes')
      })
  }
}

const $R = new Request();

const handlePostRes = () => { /* ... */ };
$R.on('onPostRes', handlePostRes); // 可以轻松在任意地方做 post 的 hook
$R.post();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      